import { ProgramCondition as GqlProgramCondition } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type ProgramConditionType = {
    toRecognize: string;
    toComplete?: string;
    precaution?: string;
    completeLimitDays?: number;
};

export class ProgramCondition implements ProgramConditionType {
    protected _toRecognize: string;

    protected _toComplete?: string;

    protected _precaution?: string;

    protected _completeLimitDays?: number;

    get toRecognize() {
        return this._toRecognize;
    }

    get toComplete() {
        return this._toComplete;
    }

    get precaution() {
        return this._precaution;
    }

    get completeLimitDays() {
        return this._completeLimitDays;
    }

    get data() {
        return {
            toRecognize: this.toRecognize,
            toComplete: this.toComplete,
            precaution: this.precaution,
            completeLimitDays: this.completeLimitDays,
        };
    }

    constructor({ toRecognize, toComplete, precaution, completeLimitDays }: ProgramConditionType) {
        this._toRecognize = toRecognize;
        this._toComplete = toComplete;
        this._precaution = precaution;
        this._completeLimitDays = completeLimitDays;
    }
}

export const getProgramConditionFromGql = ({
    toRecognize,
    toComplete,
    precaution,
    completeLimitDays,
}: GqlProgramCondition) => {
    return new ProgramCondition({
        toRecognize,
        toComplete: toComplete || undefined,
        precaution: precaution || undefined,
        completeLimitDays: typeof completeLimitDays === 'number' ? completeLimitDays : undefined,
    });
};
