import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { ProcessingMutation } from '@/utils/ctxs';
import Button from '@/components/0_atom/Button';

import SvgCheck from '@/svgs/fa-check-light';
import SvgCloudUnavailable from '@/svgs/cloud-unavailable';
import SvgCloudCaution from '@/svgs/cloud-caution';
import SvgMembersCaution from '@/svgs/members-caution';

const useSlotStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    status: {
        width: 30,
        minWidth: 'auto',
        '& svg': {
            height: 16,
            width: 16,
        },
    },
    successIcon: {
        color: theme.palette.success.main,
    },
    intervalTooShortIcon: {
        color: theme.palette.warning.main,
    },
    fetchErrorIcon: {
        color: theme.palette.error.main,
    },
    fetchingIcon: {
        color: 'rgba(255,255,255,.8)',
    },
    incorrectOldValueIcon: {
        color: theme.palette.warning.main,
    },
    text: {
        margin: 0,
    },
    textSecondary: {
        fontSize: 8,
        fontWeight: 600,
        color: 'rgba(255,255,255,.8)',
        marginTop: 4,
    },
    actions: {
        marginTop: 7,
        '&>:not(:last-child)': {
            marginRight: 7,
        },
    },
}));

const Slot: FC<{
    slot: ProcessingMutation;
}> = ({ slot }) => {
    // STYLE
    const c = useSlotStyles(useTheme());

    // DATA
    const { status, description, retry, cancel } = slot;
    const secondaryDescription = (() => {
        if (status === 'fetchError') {
            return 'サーバーに接続できませんでした';
        }
        if (status === 'incorrectOldValue') {
            return '他のメンバーの操作と競合したため、処理をキャンセルしました。';
        }
        if (status === 'intervalTooShort') {
            return 'サーバーの判断で処理がキャンセルされました。他のメンバーの操作との競合や、短時間に同じ操作を繰り返したことなどが考えられます。';
        }
        return undefined;
    })();

    return (
        <MuiListItem className={c.root} disableGutters>
            <MuiListItemIcon className={c.status}>
                {status === 'success' ? (
                    <SvgCheck className={c.successIcon} />
                ) : status === 'intervalTooShort' ? (
                    <SvgCloudCaution className={c.intervalTooShortIcon} />
                ) : status === 'fetchError' ? (
                    <SvgCloudUnavailable className={c.fetchErrorIcon} />
                ) : status === 'fetching' ? (
                    <MuiCircularProgress className={c.fetchingIcon} size={16} />
                ) : status === 'incorrectOldValue' ? (
                    <SvgMembersCaution className={c.incorrectOldValueIcon} />
                ) : (
                    <></>
                )}
            </MuiListItemIcon>
            <div>
                <MuiListItemText
                    className={c.text}
                    primary={description}
                    secondary={secondaryDescription}
                    primaryTypographyProps={{ variant: 'caption' }}
                    secondaryTypographyProps={{ className: c.textSecondary }}
                />
                {(status === 'fetchError' || status === 'intervalTooShort' || status === 'incorrectOldValue') && (
                    <div className={c.actions}>
                        {status === 'fetchError' && (
                            <>
                                <Button onClick={cancel} colorMode={'dark'} role={'cancel'} size={'small'}>
                                    {'キャンセル'}
                                </Button>
                                <Button onClick={retry} colorMode={'dark'} role={'submit'} size={'small'}>
                                    {'リトライ'}
                                </Button>
                            </>
                        )}
                        {(status === 'intervalTooShort' || status === 'incorrectOldValue') && (
                            <Button onClick={cancel} colorMode={'dark'} role={'submit'} size={'small'}>
                                {'了承'}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </MuiListItem>
    );
};
Slot.displayName = 'Slot';
export default Slot;
