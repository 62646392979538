import { FC, ReactNode, useState, useEffect, useCallback } from 'react';

import { UserCtx, useHealthCtx } from '@/utils/ctxs';
import { useFirebaseUser } from '@/utils/customHooks';
import { initMoneybackUserClient, MoneybackUserClient } from '@/utils/MoneybackClient';

// initializeをuseEffect内とreturnに使いたいので、useCallbackを使用。
// https://ja.reactjs.org/docs/hooks-faq.html

const UserProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { health } = useHealthCtx();
    const { firebaseUser, signOut, setFirebaseUser } = useFirebaseUser();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isFirebaseError, setIsFirebaseError] = useState<boolean | undefined>(undefined);
    const [userClient, setUserClient] = useState<MoneybackUserClient | undefined>(undefined);
    const initialize = useCallback(async () => {
        if (firebaseUser.idToken && health?.code === 'ok') {
            setIsFetching(true);
            const userClient = await initMoneybackUserClient({
                firebaseUserIdToken: firebaseUser.idToken,
            }).catch(() => {
                return undefined;
            });
            setUserClient(userClient);
            setIsFetching(false);
        } else {
            setUserClient(undefined);
            setIsFetching(firebaseUser.isFetching);
        }
    }, [firebaseUser, health]);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);
    useEffect(() => {
        setIsFirebaseError(firebaseUser.isError);
    }, [firebaseUser.isError]);

    return (
        <UserCtx.Provider
            value={{
                userClient,
                isFetching,
                reInitialize: initialize,
                signOut: () => {
                    signOut();
                    setUserClient(undefined);
                },
                firebaseUser: (() => {
                    const { user, idToken } = firebaseUser;
                    if (user && idToken) {
                        return {
                            uid: user.uid,
                            phone: user.phone,
                            idToken,
                        };
                    }
                    return undefined;
                })(),
                isFirebaseError,
                setFirebaseUser: (props) => {
                    setIsFetching(true);
                    setFirebaseUser(props);
                },
            }}
        >
            {children}
        </UserCtx.Provider>
    );
};
UserProvider.displayName = 'UserProvider';
export default UserProvider;
