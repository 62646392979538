import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { ProgramClosure as GqlProgramClosure } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type ProgramClosureType = {
    endedAt: Date;
    reason: string;
};

export class ProgramClosure implements ProgramClosureType {
    protected _endedAt: Date;

    protected _reason: string;

    get endedAt() {
        return this._endedAt;
    }

    get reason() {
        return this._reason;
    }

    get data() {
        return {
            endedAt: this.endedAt,
            reason: this.reason,
        };
    }

    constructor({ endedAt, reason }: ProgramClosureType) {
        this._endedAt = endedAt;
        this._reason = reason;
    }
}

export const getProgramClosureFromGql = ({ endedAt, reason }: GqlProgramClosure) => {
    return new ProgramClosure({
        endedAt: GqlDateTimeData.serialize(endedAt),
        reason,
    });
};
