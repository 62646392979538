import { Price as GqlPrice, DecimalRoundRule } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type PriceType = {
    beforeTax: number;
    taxRate: number;
    decimalRoundRule: DecimalRoundRule;
    total: number;
};

export class Price implements PriceType {
    protected _beforeTax: number;

    protected _taxRate: number;

    protected _decimalRoundRule: DecimalRoundRule;

    protected _total: number;

    get beforeTax() {
        return this._beforeTax;
    }

    get taxRate() {
        return this._taxRate;
    }

    get decimalRoundRule() {
        return this._decimalRoundRule;
    }

    get total() {
        return this._total;
    }

    get data() {
        return {
            beforeTax: this.beforeTax,
            taxRate: this.taxRate,
            decimalRoundRule: this.decimalRoundRule,
            total: this.total,
        };
    }

    constructor({ beforeTax, taxRate, decimalRoundRule, total }: PriceType) {
        this._beforeTax = beforeTax;
        this._taxRate = taxRate;
        this._decimalRoundRule = decimalRoundRule;
        this._total = total;
    }
}

export const getPriceFromGql = ({ beforeTax, taxRate, decimalRoundRule, total }: GqlPrice) => {
    return new Price({
        beforeTax,
        taxRate,
        decimalRoundRule,
        total,
    });
};
