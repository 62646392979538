import { useEffect } from 'react';
import { AppProps } from 'next/app';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorProvider from '@/providers/ErrorProvider';
import HealthProvider from '@/providers/HealthProvider';
import MutationsProvider from '@/providers/MutationsProvider';
import UserProvider from '@/providers/UserProvider';
import NetworkIndicator from '@/components/2_org/NetworkIndicator';
import muiTheme from '@/styles/muiTheme';

const App = ({ Component, pageProps }: AppProps) => {
    // USEEFFECT
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles !== null && jssStyles.parentElement !== null) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <ThemeProvider theme={muiTheme}>
            <ErrorProvider>
                <HealthProvider>
                    <MutationsProvider>
                        <UserProvider>
                            <Component {...pageProps} />
                            <NetworkIndicator />
                            <CssBaseline />
                        </UserProvider>
                    </MutationsProvider>
                </HealthProvider>
            </ErrorProvider>
        </ThemeProvider>
    );
};

App.displayName = 'App';
export default App;
