import { Transfer as GqlTransfer } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { Price, getPriceFromGql } from '@/utils/MoneybackClient/models/Price';
import { BankAccountInput, getBankAccountInputFromGql } from '@/utils/MoneybackClient/models/BankAccount';
import {
    RemittanceResult,
    getRemittanceResult,
    getRemittanceResultFromGql,
} from '@/utils/MoneybackClient/models/Transfer/data/Edge';

export type TransferType = {
    id: string;
    createdAt: Date;
    bankAccount: BankAccountInput;
    amount: number;
    feeOnIssuer: Price;
    remitterNameKana: string;
    result: RemittanceResult | undefined;
};

export class Transfer implements TransferType {
    protected _id: string;

    protected _createdAt: Date;

    protected _bankAccount: BankAccountInput;

    protected _amount: number;

    protected _feeOnIssuer: Price;

    protected _remitterNameKana: string;

    protected _result: RemittanceResult | undefined;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get bankAccount() {
        return this._bankAccount;
    }

    get amount() {
        return this._amount;
    }

    get feeOnIssuer() {
        return this._feeOnIssuer;
    }

    get remitterNameKana() {
        return this._remitterNameKana;
    }

    get result() {
        return this._result;
    }

    get data() {
        return {
            id: this._id,
            createdAt: this._createdAt,
            bankAccount: this._bankAccount,
            amount: this._amount,
            feeOnIssuer: this._feeOnIssuer,
            remitterNameKana: this._remitterNameKana,
            result: this._result,
        };
    }

    constructor({
        id,
        createdAt,

        bankAccount,
        amount,
        feeOnIssuer,
        remitterNameKana,
        result,
    }: TransferType) {
        this._id = id;
        this._createdAt = createdAt;
        this._bankAccount = bankAccount;
        this._amount = amount;
        this._feeOnIssuer = feeOnIssuer;
        this._remitterNameKana = remitterNameKana;
        this._result = result ? getRemittanceResult(result) : undefined;
    }
}

export const getTransferFromGql = ({
    id,
    createdAt,
    bankAccount,
    amount,
    feeOnIssuer,
    remitterNameKana,
    result,
}: GqlTransfer) => {
    return new Transfer({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        bankAccount: getBankAccountInputFromGql(bankAccount),
        amount,
        feeOnIssuer: getPriceFromGql(feeOnIssuer),
        remitterNameKana,
        result: result ? getRemittanceResultFromGql(result) : undefined,
    });
};
