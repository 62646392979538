import { useEffect, useState } from 'react';

export const useScrolledHeightFromBottomBool = ({ heightFromBottom }: { heightFromBottom: number }) => {
    // HOOKS
    const [isLesser, setIsLesser] = useState<boolean | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (process.browser && window && window.document && (window.document.body || window.document.documentElement)) {
            window.addEventListener(
                'scroll',
                (event) => {
                    const scrollTop =
                        (window.document.body && window.document.body.scrollTop) ||
                        (window.document.documentElement && window.document.documentElement.scrollTop);
                    const remainingScrollHeight =
                        window.document.documentElement.scrollHeight -
                        window.document.documentElement.clientHeight -
                        scrollTop;
                    const newValue = remainingScrollHeight <= 0 || remainingScrollHeight <= heightFromBottom;
                    if (newValue !== isLesser) {
                        setIsLesser(remainingScrollHeight <= heightFromBottom);
                    }
                },
                true
            );
        }
    }, [process.browser]);

    return isLesser;
};
