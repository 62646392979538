import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { getEnvVariables } from '@/utils/envVariables';

export const getFirebaseCore = () => {
    const existingApp = firebase.apps.find((app) => app.name === 'core');
    if (existingApp) {
        return existingApp;
    }
    const { apiKey, authDomain, projectId, storageBucket } = getEnvVariables().firebase.core;
    return firebase.initializeApp(
        {
            apiKey,
            authDomain,
            projectId,
            storageBucket,
        },
        'core'
    );
};

export const getFirebaseAuth = (language?: 'en' | 'ja') => {
    const app = getFirebaseCore();
    const auth = firebase.auth(app);
    auth.languageCode = language || 'ja';
    return auth;
};

export const getFirebaseStorage = () => {
    const app = getFirebaseCore();
    return firebase.storage(app);
};

export const RecaptchaVerifier = (() => {
    getFirebaseCore();
    return firebase.auth.RecaptchaVerifier;
})();

export const PhoneAuthProvider = (() => {
    getFirebaseCore();
    return firebase.auth.PhoneAuthProvider;
})();

export type ConfirmationResult = firebase.auth.ConfirmationResult;
export type RecaptchaVerifierType = firebase.auth.RecaptchaVerifier;
export type UserCredential = firebase.auth.UserCredential;
