import { User as GqlUser } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { BankAccount, getBankAccountFromGql } from '@/utils/MoneybackClient/models/BankAccount';

export type UserType = {
    id: string;
    phone: string;
    bankAccount?: BankAccount;
};

export class User implements UserType {
    protected _id: string;

    protected _phone: string;

    protected _bankAccount?: BankAccount;

    get id() {
        return this._id;
    }

    get phone() {
        return this._phone;
    }

    get bankAccount() {
        return this._bankAccount;
    }

    get data() {
        return {
            id: this.id,
            phone: this.phone,
            bankAccount: this.bankAccount,
        };
    }

    constructor({ id, phone, bankAccount }: UserType) {
        this._id = id;
        this._phone = phone;
        this._bankAccount = bankAccount;
    }
}

export const getUserFromGql = ({ id, phone, bankAccount }: GqlUser) => {
    return new User({
        id,
        phone,
        bankAccount: bankAccount ? getBankAccountFromGql(bankAccount) : undefined,
    });
};
