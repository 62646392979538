type UodkaEnv = 'development' | 'staging' | 'production';

const getUodkaEnv = (): UodkaEnv => {
    const str = process.env.UODKA_ENV;
    if (str === 'production') {
        return 'production';
    }
    if (str === 'staging') {
        return 'staging';
    }
    if (str === 'development') {
        return 'development';
    }
    throw new Error(`getUodkaEnv: Unexpected process.env.UODKA_ENV got->${str}`);
};

type EnvVariables = {
    uodkaEnv: UodkaEnv;
    rootUrl: string;
    api: {
        graphqlEndpointUrl: string;
    };
    firebase: {
        core: {
            projectId: string;
            authDomain: string;
            apiKey: string;
            storageBucket: string;
        };
    };
    moneyback: {
        mediaId: string;
    };
};

export const getEnvVariables = (): EnvVariables => {
    return {
        uodkaEnv: getUodkaEnv(),
        rootUrl: process.env.ROOT_URL || '',
        api: {
            graphqlEndpointUrl: process.env.API_GRAPHQL_ENDPOINT_URL || '',
        },
        firebase: {
            core: {
                projectId: process.env.FIREBASE_CORE_PROJECT_ID || '',
                authDomain: process.env.FIREBASE_CORE_AUTH_DOMAIN || '',
                apiKey: process.env.FIREBASE_CORE_API_KEY || '',
                storageBucket: process.env.FIREBASE_CORE_STORAGE_BUCKET || '',
            },
        },
        moneyback: {
            mediaId: process.env.MONEYBACK_MEDIAID || '',
        },
    };
};
