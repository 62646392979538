import { FC, ReactNode, useMemo } from 'react';

import { HealthCtx } from '@/utils/ctxs';
import { useHealth } from '@/providers/HealthProvider/useHealth';

const HealthProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const health = useHealth();

    // MEMO
    const content = useMemo(
        () => <HealthCtx.Provider value={health}>{children}</HealthCtx.Provider>,
        [children, health]
    );

    return content;
};
HealthProvider.displayName = 'HealthProvider';
export default HealthProvider;
