import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useScrolledHeightFromBottomBool } from '@/utils/customHooks/useScrolledHeightFromBottomBool';

export const useScrolledDown = () => {
    // HOOKS
    const isLesser = useScrolledHeightFromBottomBool({
        heightFromBottom: 100,
    });
    const isScrolledUp = useScrollTrigger();

    // DATA
    const isScrolledDown = isLesser === undefined ? undefined : isLesser || isScrolledUp;

    return isScrolledDown;
};
