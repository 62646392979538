import { MutationValidationResult } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import {
    BankAccountInputType,
    getBankAccountFromGql,
    getBankAccountInputFromGql,
} from '@/utils/MoneybackClient/models/BankAccount';
import { User, UserType } from '@/utils/MoneybackClient/models/User/data/User';
import { TicketForUser, getTicketForUserFromGql } from '@/utils/MoneybackClient/models/Ticket';

interface UserAPIType {
    updateBankAccount: (bankAccount: BankAccountInputType | undefined) => Promise<MutationValidationResult>;
    createTicket: (input: {
        programId: string;
        campaignId: string;
        userAgent: string | undefined;
        referer: string | undefined;
    }) => Promise<TicketForUser>;
}

interface ConstructorInput extends UserType {
    graphqlSdk: GraphQLSdk;
}

export class UserAPI extends User implements UserAPIType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async updateBankAccount(bankAccount: BankAccountInputType | undefined) {
        const { updateUserBankAccount } = await this._graphqlSdk.updateUserBankAccount({
            input: {
                requiredInterval: 0,
                newValue: bankAccount ? getBankAccountInputFromGql(bankAccount).data : undefined,
                oldValue: this.bankAccount?.data,
            },
        });
        this._bankAccount = updateUserBankAccount.user.bankAccount
            ? getBankAccountFromGql(updateUserBankAccount.user.bankAccount)
            : undefined;
        return updateUserBankAccount.result;
    }

    async remove() {
        await this._graphqlSdk.removeUser();
    }

    async createTicket({
        programId,
        campaignId,
        userAgent,
        referer,
    }: {
        programId: string;
        campaignId: string;
        userAgent: string | undefined;
        referer: string | undefined;
    }) {
        const { createTicket } = await this._graphqlSdk.createTicket({
            input: {
                programId,
                campaignId,
                userAgent,
                referer,
            },
        });
        return getTicketForUserFromGql(createTicket.ticket);
    }
}
