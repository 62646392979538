import { GraphQLClient } from 'graphql-request';
import { getSdk, ServiceHealthCode } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { graphqlEndPoint } from '@/utils/MoneybackClient/config';

export const getGraphqlSdk = ({ firebaseUserIdToken }: { firebaseUserIdToken?: string }) => {
    const client = new GraphQLClient(graphqlEndPoint, {
        headers: {
            token: firebaseUserIdToken || '',
            authas: 'serviceuser',
        },
    });
    return getSdk(client);
};

export type GraphQLSdk = ReturnType<typeof getGraphqlSdk>;

export type ServiceHealth = {
    code: ServiceHealthCode;
    message?: string;
    url?: string;
};
export const getServiceHealth = async (): Promise<ServiceHealth> => {
    const { getServiceHealth } = await getGraphqlSdk({ firebaseUserIdToken: undefined }).getServiceHealth();
    return {
        code: getServiceHealth.code,
        message: getServiceHealth.message || undefined,
        url: getServiceHealth.url || undefined,
    };
};
