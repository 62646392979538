import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { BankAccountInput, BankAccountInputType, getUserFromGql } from '@/utils/MoneybackClient/models';
import { MoneybackUserClient } from '@/utils/MoneybackClient/clients/User';

type MoneybackFirebaseUserClientType = {
    signup: (input: { bankAccount?: BankAccountInputType }) => Promise<MoneybackUserClient>;
};

type ConstructorInput = {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackFirebaseUserClient implements MoneybackFirebaseUserClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async signup({ bankAccount }: { bankAccount?: BankAccountInputType }) {
        const { createUser } = await this._graphqlSdk.createUser({
            input: {
                bankAccount: bankAccount ? new BankAccountInput(bankAccount).data : undefined,
            },
        });
        const { user } = createUser;
        return new MoneybackUserClient({
            graphqlSdk: this._graphqlSdk,
            ...getUserFromGql(user).data,
        });
    }
}
