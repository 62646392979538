import {
    CampaignForPublic as GqlCampaignForPublic,
    ProgramForPublic as GqlProgramForPublic,
    MediaForPublic as GqlMediaForPublic,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import {
    CampaignForPublic,
    getCampaignForPublicFromGql,
    ProgramForPublic,
    getProgramForPublicFromGql,
    getMediaForPublicFromGql,
    MediaForPublic,
} from '@/utils/MoneybackClient/models';

export type {
    CampaignForPublic as GqlCampaignForPublic,
    ProgramForPublic as GqlProgramForPublic,
    MediaForPublic as GqlMediaForPublic,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

type MoneybackPublicClientType = {
    getTos: () => Promise<string>;
    getPrivacypolicy: () => Promise<string>;
    getCampaignForPublic: (input: { campaignUri: string }) => Promise<
        | {
              campaign: CampaignForPublic;
              program: ProgramForPublic;
              media: MediaForPublic;
          }
        | undefined
    >;
    getGqlCampaignForPublic: (input: { campaignUri: string }) => Promise<{
        campaign: GqlCampaignForPublic;
        program: GqlProgramForPublic;
        media: GqlMediaForPublic;
    } | null>;
    getPublicCampaigns: () => Promise<
        {
            campaign: CampaignForPublic;
            program: ProgramForPublic;
        }[]
    >;
    getGqlPublicCampaigns: () => Promise<
        {
            campaign: GqlCampaignForPublic;
            program: GqlProgramForPublic;
        }[]
    >;
    getAllCampaigns: () => Promise<CampaignForPublic[]>;
};

type ConstructorInput = {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackPublicClient implements MoneybackPublicClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async getTos() {
        const { getTos } = await this._graphqlSdk.getTos();
        const { tos } = getTos;
        return tos;
    }

    async getPrivacypolicy() {
        const { getPrivacypolicy } = await this._graphqlSdk.getPrivacypolicy();
        const { privacypolicy } = getPrivacypolicy;
        return privacypolicy;
    }

    async getCampaignForPublic({ campaignUri }: { campaignUri: string }) {
        const { getCampaignForPublic } = await this._graphqlSdk.getCampaignForPublic({
            input: {
                campaignUri,
            },
        });
        if (getCampaignForPublic) {
            const { campaign, program, media } = getCampaignForPublic;
            return {
                campaign: getCampaignForPublicFromGql(campaign),
                program: await getProgramForPublicFromGql(program),
                media: getMediaForPublicFromGql(media),
            };
        }
        return undefined;
    }

    async getGqlCampaignForPublic({ campaignUri }: { campaignUri: string }) {
        const { getCampaignForPublic } = await this._graphqlSdk.getCampaignForPublic({
            input: {
                campaignUri,
            },
        });
        return getCampaignForPublic || null;
    }

    async getPublicCampaigns() {
        const { getPublicCampaigns } = await this._graphqlSdk.getPublicCampaigns();
        const { campaigns } = getPublicCampaigns;
        return Promise.all(
            campaigns.map(async ({ campaign, program }) => ({
                campaign: getCampaignForPublicFromGql(campaign),
                program: await getProgramForPublicFromGql(program),
            }))
        );
    }

    async getGqlPublicCampaigns() {
        const { getPublicCampaigns } = await this._graphqlSdk.getPublicCampaigns();
        const { campaigns } = getPublicCampaigns;
        return campaigns;
    }

    async getAllCampaigns() {
        const { getAllCampaigns } = await this._graphqlSdk.getAllCampaigns();
        const { campaigns } = getAllCampaigns;
        return campaigns.map((campaign) => getCampaignForPublicFromGql(campaign));
    }
}
