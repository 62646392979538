import { createContext, useContext } from 'react';

import { MoneybackUserClient } from '@/utils/MoneybackClient';

type FirebaseUser = {
    uid: string;
    phone: string;
    idToken: string;
};
type UserCtxType = {
    userClient?: MoneybackUserClient;
    isFetching: boolean;
    reInitialize: () => Promise<void>;
    signOut: () => void;
    firebaseUser?: FirebaseUser;
    isFirebaseError?: boolean;
    setFirebaseUser: (firebaseUser: FirebaseUser) => void;
};
export const UserCtx = createContext<UserCtxType>({
    isFetching: true,
    reInitialize: () => new Promise((r) => r()),
    signOut: () => undefined,
    setFirebaseUser: () => undefined,
});
export const useUserCtx = () => {
    return useContext(UserCtx);
};
export const useUserCtxAbsolutely = () => {
    const { userClient, firebaseUser, ...otherProps } = useUserCtx();
    if (!userClient || !firebaseUser) {
        throw new Error('useUserCtxAbsolutely: !userClient || !firebaseUser');
    }
    return {
        userClient,
        firebaseUser,
        ...otherProps,
    };
};
