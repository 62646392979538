import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { CampaignForPublic as GqlCampaignForPublic } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type CampaignForPublicType = {
    id: string;
    createdAt: Date;
    updatedAt?: Date;
    cashbackAmount: number;
    uri: string;
    endReceptionAt?: Date;
};

export class CampaignForPublic implements CampaignForPublicType {
    protected _id: string;

    protected _createdAt: Date;

    protected _updatedAt?: Date;

    protected _cashbackAmount: number;

    protected _uri: string;

    protected _endReceptionAt?: Date;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    get cashbackAmount() {
        return this._cashbackAmount;
    }

    get uri() {
        return this._uri;
    }

    get endReceptionAt() {
        return this._endReceptionAt;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            cashbackAmount: this._cashbackAmount,
            uri: this._uri,
            endReceptionAt: this._endReceptionAt,
        };
    }

    constructor({ id, createdAt, updatedAt, cashbackAmount, uri, endReceptionAt }: CampaignForPublicType) {
        this._id = id;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
        this._cashbackAmount = cashbackAmount;
        this._uri = uri;
        this._endReceptionAt = endReceptionAt;
    }
}

export const getCampaignForPublicFromGql = ({
    id,
    createdAt,
    updatedAt,
    cashbackAmount,
    uri,
    endReceptionAt,
}: GqlCampaignForPublic) => {
    return new CampaignForPublic({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        updatedAt: updatedAt ? GqlDateTimeData.serialize(updatedAt) : undefined,
        cashbackAmount,
        uri,
        endReceptionAt: endReceptionAt ? GqlDateTimeData.serialize(endReceptionAt) : undefined,
    });
};
