import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { MediaForPublic as GqlMediaForPublic } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type MediaForPublicType = {
    id: string;
    name: string;
    url: string;
    archivedAt?: Date;
};

export class MediaForPublic implements MediaForPublicType {
    protected _id: string;

    protected _name: string;

    protected _url: string;

    protected _archivedAt?: Date;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get url() {
        return this._url;
    }

    get archivedAt() {
        return this._archivedAt;
    }

    get data() {
        return {
            id: this.id,
            name: this.name,
            url: this.url,
            archivedAt: this.archivedAt,
        };
    }

    constructor({ id, name, url, archivedAt }: MediaForPublicType) {
        this._id = id;
        this._name = name;
        this._url = url;
        this._archivedAt = archivedAt;
    }
}

export const getMediaForPublicFromGql = ({ id, name, url, archivedAt }: GqlMediaForPublic) => {
    return new MediaForPublic({
        id,
        name,
        url,
        archivedAt: archivedAt ? GqlDateTimeData.serialize(archivedAt) : undefined,
    });
};
