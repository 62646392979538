import { useEffect, useState, useCallback } from 'react';

import { initMoneybackPublicClient } from '@/utils/MoneybackClient';

export const usePrivacypolicy = () => {
    // HOOKS
    const [privacypolicy, setPrivacypolicy] = useState<string | undefined>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const client = initMoneybackPublicClient();
        const fetchedPrivacypolicy = await client.getPrivacypolicy();
        setPrivacypolicy(fetchedPrivacypolicy);
    }, []);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);

    return {
        privacypolicy,
    };
};
