import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import DotsProgress from '@/components/0_atom/DotsProgress';
import Button from '@/components/0_atom/Button';

import SvgLaptop from '@/svgs/fa-laptop-light';
import SvgCloud from '@/svgs/cloud';
import SvgCloudSuccess from '@/svgs/cloud-success';
import SvgCloudUnavailable from '@/svgs/cloud-unavailable';

const useHealthStyles = makeStyles((theme) => ({
    root: {},
    icons: {
        display: 'flex',
        height: 14,
    },
    laptop: {
        marginRight: 6,
    },
    dots: {
        marginRight: 6,
    },
    cloudSuccess: {
        color: theme.palette.success.main,
    },
    cloudUnavailable: {
        color: theme.palette.warning.main,
    },
    cloudPlain: {
        color: 'rgba(0255,255,255,.2)',
    },
    title: {
        fontWeight: 600,
        display: 'block',
        marginTop: 7,
    },
    message: {
        fontSize: theme.typography.caption.fontSize,
        marginTop: 7,
    },
    url: {
        textDecoration: 'none',
        marginTop: 7,
    },
}));

export interface ServerState {
    status: 'fetching' | 'fetchError' | 'authFetchError' | 'ok' | 'outOfService';
    message?: string;
    url?: string;
}

const Health: FC<{ serverState: ServerState }> = ({ serverState }) => {
    // STYLE
    const c = useHealthStyles(useTheme());

    return (
        <div className={c.root}>
            <div>
                <div className={c.icons}>
                    <SvgLaptop className={c.laptop} />
                    <DotsProgress
                        className={c.dots}
                        state={
                            serverState.status === 'fetching'
                                ? 'inProgress'
                                : serverState.status === 'fetchError' || serverState.status === 'authFetchError'
                                ? 'off'
                                : 'completed'
                        }
                    />
                    {serverState.status === 'ok' ? (
                        <SvgCloudSuccess className={c.cloudSuccess} />
                    ) : serverState.status === 'outOfService' ? (
                        <SvgCloudUnavailable className={c.cloudUnavailable} />
                    ) : (
                        <SvgCloud className={c.cloudPlain} />
                    )}
                </div>
                <MuiTypography variant={'caption'} className={c.title}>
                    {serverState.status === 'ok'
                        ? 'サーバーに接続済み'
                        : serverState.status === 'fetching'
                        ? 'サーバーに接続中'
                        : serverState.status === 'outOfService'
                        ? '現在サービスを利用できません'
                        : serverState.status === 'authFetchError'
                        ? '認証サーバーに接続できません'
                        : 'サーバーに接続できません'}
                </MuiTypography>
            </div>
            {serverState.message && <div className={c.message}>{serverState.message}</div>}
            {serverState.url && (
                <Button
                    className={c.url}
                    href={serverState.url}
                    // component={'a'}
                    // target={'_blank'}
                    // rel={'noreferrer noopener'}
                >
                    {'詳細を確認'}
                </Button>
            )}
        </div>
    );
};
Health.displayName = 'Health';
export default Health;
