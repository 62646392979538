import { useState, useEffect, useCallback } from 'react';

export const useFormField = <T>(initialValue: T) => {
    // HOOKS
    const [value, setValue] = useState(initialValue);

    // CALLBACK
    const handleSetValue = useCallback(
        (newValue: T) => {
            if (newValue !== value) {
                setValue(newValue);
            }
        },
        [value]
    );

    // HANDLER
    // const handleSetValue = (newValue: T) => {
    //     if (newValue !== value) {
    //         setValue(newValue);
    //     }
    // };

    // USEEFFECT
    useEffect(() => {
        handleSetValue(initialValue);
    }, [initialValue]);

    return [value, handleSetValue] as const;
};
