import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import {
    TicketSettlementForUser as GqlTicketSettlementForUser,
    TicketJudgement,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type TicketSettlementForUserType = {
    at: Date;
    judgement: TicketJudgement;
};

export class TicketSettlementForUser implements TicketSettlementForUserType {
    protected _at: Date;

    protected _judgement: TicketJudgement;

    get at() {
        return this._at;
    }

    get judgement() {
        return this._judgement;
    }

    get data() {
        return {
            at: this.at,
            judgement: this._judgement,
        };
    }

    constructor({ at, judgement }: TicketSettlementForUserType) {
        this._at = at;
        this._judgement = judgement;
    }
}

export const getTicketSettlementForUserFromGql = ({ at, judgement }: GqlTicketSettlementForUser) => {
    return new TicketSettlementForUser({
        at: GqlDateTimeData.serialize(at),
        judgement,
    });
};
