import { getKana } from '@/utils/MoneybackClient/utils/getKana';

const remitterNameHalfWidthLimit = 40;
const vaHolderNameUserSpaceKanaLength = 19;

export const getValidVaHolderNameUserSpace = (kana: string) => {
    const prohibitedCharactersForUserSpace = ['/', '／'];
    const isContainsProhibitedCharactersForUserSpace = !!prohibitedCharactersForUserSpace.find((character) =>
        kana.match(character)
    );
    if (!isContainsProhibitedCharactersForUserSpace) {
        const kanas = getKana(kana);
        if (kanas) {
            if (kanas.halfWidthKatakana.length <= vaHolderNameUserSpaceKanaLength) {
                return {
                    isValid: true,
                    fullWidthKatakana: kanas.fullWidthKatakana,
                    halfWidthKatakana: kanas.halfWidthKatakana,
                };
            }
        }
    }
    return {
        isValid: false,
        fullWidthKatakana: undefined,
        halfWidthKatakana: undefined,
    };
};

export const getValidRemitterName = (kana: string) => {
    const kanas = getKana(kana);
    if (kanas) {
        if (kanas.halfWidthKatakana.length <= remitterNameHalfWidthLimit) {
            return {
                isValid: true,
                fullWidthKatakana: kanas.fullWidthKatakana,
                halfWidthKatakana: kanas.halfWidthKatakana,
            };
        }
    }
    return {
        isValid: false,
        fullWidthKatakana: undefined,
        halfWidthKatakana: undefined,
    };
};
