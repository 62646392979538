import { useState, createRef, useCallback } from 'react';

import {
    getFirebaseAuth,
    RecaptchaVerifier,
    getFirebaseCore,
    ConfirmationResult,
    RecaptchaVerifierType,
} from '@/utils/GoogleCloud/firebase';
import { useFirebaseUser } from '@/utils/customHooks';

export const usePhoneAuth = (input?: { recaptchaContainerId?: string }) => {
    // INPUT
    const recaptchaContainerId = input?.recaptchaContainerId || 'recaptcha-container-manuallyprepared';

    // REF
    const containerRef = createRef<HTMLDivElement>();

    // HOOKS
    const { setFirebaseUser } = useFirebaseUser();
    const [authCodeStatus, setAuthCodeStatus] = useState<
        | 'waitingForRecaptcha'
        | 'phoneWrong'
        | 'sending'
        | 'sent'
        | 'confirming'
        | 'confirmed'
        | 'invalid'
        | 'expired'
        | undefined
    >(undefined);
    const [smsSentResult, setSmsSentResult] = useState<ConfirmationResult | undefined>(undefined);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifierType | undefined>(undefined);

    // DATA
    const recaptchaContainerElm = (
        <div
            id={recaptchaContainerId}
            ref={containerRef}
            style={{
                // padding: '1rem',
                // height: '100vh',
                width: '100vw',
                display: authCodeStatus === 'waitingForRecaptcha' ? undefined : 'none',
            }}
        />
    );

    // CALLBACK
    const destroyRecaptcha = useCallback(() => {
        if (recaptchaVerifier) {
            recaptchaVerifier.clear();
            setRecaptchaVerifier(undefined);
        }
    }, [recaptchaVerifier]);

    const handleRenderRecaptcha = useCallback(async () => {
        destroyRecaptcha();
        const newVerifier = new RecaptchaVerifier(
            recaptchaContainerId,
            {
                size: 'normal',
                callback: () => {
                    setAuthCodeStatus('sending');
                },
                'expired-callback': () => {
                    setAuthCodeStatus('expired');
                },
                'error-callback': () => {
                    setAuthCodeStatus('expired');
                },
            },
            getFirebaseCore()
        );
        setRecaptchaVerifier(newVerifier);
        await newVerifier.render();
        return newVerifier;
    }, [destroyRecaptcha, recaptchaContainerId]);

    const specifyPhone = useCallback(
        async ({ phone }: { phone: string }) => {
            setAuthCodeStatus('waitingForRecaptcha');
            const auth = getFirebaseAuth();
            const newVerifier = await handleRenderRecaptcha();
            const result = await auth.signInWithPhoneNumber(phone.replace(/^0/, '+81'), newVerifier).catch((e) => {
                console.error(e);
                setAuthCodeStatus('phoneWrong');
                // throw new Error('specifyPhone: failed.');
                return undefined;
            });
            if (result) {
                setSmsSentResult(result);
                setAuthCodeStatus('sent');
            }
        },
        [handleRenderRecaptcha]
    );

    const verifyAuthCode = useCallback(
        async ({ authCode }: { authCode: string }) => {
            if (smsSentResult) {
                setAuthCodeStatus('confirming');
                const r = await smsSentResult.confirm(authCode).catch(() => {
                    return undefined;
                });
                if (r?.user) {
                    setAuthCodeStatus('confirmed');
                    const { uid, providerData } = r.user;
                    const idToken = await r.user.getIdToken();
                    setFirebaseUser({
                        uid,
                        phone: (
                            providerData?.find((provider) => provider?.providerId === 'phone')?.phoneNumber || ''
                        ).replace(/^\+81/, '0'),
                        idToken,
                    });
                } else {
                    setAuthCodeStatus('invalid');
                }
            }
        },
        [smsSentResult]
    );

    const resetPhoneAuth = useCallback(() => {
        setAuthCodeStatus(undefined);
        destroyRecaptcha();
    }, [destroyRecaptcha]);

    return {
        recaptchaContainerElm,
        specifyPhone,
        authCodeStatus,
        resetPhoneAuth,
        verifyAuthCode,
    };
};
