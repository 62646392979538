import { useEffect, useState, useCallback } from 'react';

import { initMoneybackPublicClient } from '@/utils/MoneybackClient';

export const useTos = ({ isStartFetch }: { isStartFetch: boolean }) => {
    // HOOKS
    const [tos, setTos] = useState<string | undefined>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const client = initMoneybackPublicClient();
        const fetchedTos = await client.getTos();
        setTos(fetchedTos);
    }, []);

    // USEEFFECT
    useEffect(() => {
        if (isStartFetch && !tos) {
            initialize();
        }
    }, [initialize, isStartFetch]);

    return {
        tos,
    };
};
