import { createContext, useContext } from 'react';

import { ServiceHealth } from '@/utils/MoneybackClient';

export type ServiceHealthCtxType = {
    health?: ServiceHealth;
    fetchStatus: 'fetchError' | 'fetching' | 'fetched';
};

export const HealthCtx = createContext<ServiceHealthCtxType>({
    fetchStatus: 'fetching',
});
export const useHealthCtx = () => {
    return useContext(HealthCtx);
};
