import { TicketWithCampaignForUser as GqlTicketWithCampaignForUser } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { TicketForUser, getTicketForUserFromGql } from '@/utils/MoneybackClient/models/Ticket/data/TicketForUser';
import { CampaignForPublic, getCampaignForPublicFromGql } from '@/utils/MoneybackClient/models/Campaign';
import { ProgramForPublic, getProgramForPublicFromGql } from '@/utils/MoneybackClient/models/Program';

export type TicketWithCampaignForUserType = {
    ticket: TicketForUser;
    campaign: CampaignForPublic;
    program: ProgramForPublic;
};

export class TicketWithCampaignForUser implements TicketWithCampaignForUserType {
    protected _ticket: TicketForUser;

    protected _campaign: CampaignForPublic;

    protected _program: ProgramForPublic;

    get ticket() {
        return this._ticket;
    }

    get campaign() {
        return this._campaign;
    }

    get program() {
        return this._program;
    }

    get data() {
        return {
            ticket: this.ticket,
            campaign: this.campaign,
            program: this.program,
        };
    }

    constructor({ ticket, campaign, program }: TicketWithCampaignForUserType) {
        this._ticket = ticket;
        this._campaign = campaign;
        this._program = program;
    }
}

export const getTicketWithCampaignForUserFromGql = async ({
    ticket,
    campaign,
    program,
}: GqlTicketWithCampaignForUser) => {
    return new TicketWithCampaignForUser({
        ticket: getTicketForUserFromGql(ticket),
        campaign: getCampaignForPublicFromGql(campaign),
        program: await getProgramForPublicFromGql(program),
    });
};
