import {
    UserAPI,
    TicketWithCampaignForUser,
    getTicketWithCampaignForUserFromGql,
    TicketForUser,
    getTicketForUserFromGql,
    getCampaignForPublicFromGql,
    CampaignForPublic,
} from '@/utils/MoneybackClient/models';

type MoneybackUserClientType = {
    getTicketForUser: (input: { programId: string }) => Promise<
        | {
              ticket: TicketForUser;
              campaign: CampaignForPublic;
          }
        | undefined
    >;
    getTicketsForUser: () => Promise<TicketWithCampaignForUser[]>;
};

export class MoneybackUserClient extends UserAPI implements MoneybackUserClientType {
    async getTicketForUser({ programId }: { programId: string }) {
        const { getTicketForUser } = await this._graphqlSdk.getTicketForUser({
            input: {
                programId,
            },
        });
        const { ticket, campaign } = getTicketForUser;
        return ticket && campaign
            ? {
                  ticket: getTicketForUserFromGql(ticket),
                  campaign: getCampaignForPublicFromGql(campaign),
              }
            : undefined;
    }

    async getTicketsForUser() {
        const { getTicketsForUser } = await this._graphqlSdk.getTicketsForUser();
        const { tickets } = getTicketsForUser;
        return Promise.all(tickets.map((ticket) => getTicketWithCampaignForUserFromGql(ticket)));
    }
}
