import { BankAccount as GqlBankAccount } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { getKana } from '@/utils/MoneybackClient/utils/getKana';

export type BankAccountType = {
    bankCode: string;
    branchCode: string;
    accountNumber: string;
    accountHolderName: string;
};

export class BankAccount implements BankAccountType {
    protected _bankCode: string;

    protected _branchCode: string;

    protected _accountNumber: string;

    protected _accountHolderName: string;

    get bankCode() {
        return this._bankCode;
    }

    get branchCode() {
        return this._branchCode;
    }

    get accountNumber() {
        return this._accountNumber;
    }

    get accountHolderName() {
        return this._accountHolderName;
    }

    get data() {
        return {
            bankCode: this.bankCode,
            branchCode: this.branchCode,
            accountNumber: this.accountNumber,
            accountHolderName: this.accountHolderName,
        };
    }

    constructor({ bankCode, branchCode, accountNumber, accountHolderName }: BankAccountType) {
        this._bankCode = bankCode;
        this._branchCode = branchCode;
        this._accountNumber = accountNumber;
        this._accountHolderName = (() => {
            const result = getKana(accountHolderName);
            if (result) {
                return result.fullWidthKatakana;
            }
            throw new Error('BankAccount.constructor: Unexpectedly getKana failed.');
        })();
    }
}

export const getBankAccountFromGql = ({ bankCode, branchCode, accountNumber, accountHolderName }: GqlBankAccount) => {
    return new BankAccount({
        bankCode,
        branchCode,
        accountNumber,
        accountHolderName,
    });
};
