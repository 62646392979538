import { getGraphqlSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { MoneybackUserClient } from '@/utils/MoneybackClient/clients';
import { getUserFromGql } from '@/utils/MoneybackClient/models';

export const initMoneybackUserClient = async (input: { firebaseUserIdToken: string }) => {
    const graphqlSdk = getGraphqlSdk(input);
    const { getUser } = await graphqlSdk.getUser().catch(({ message }) => {
        console.error('message->', message);
        throw new Error('initUodkaUserClient: invalid firebaseUserIdToken');
    });
    const { user } = getUser;
    if (user) {
        return new MoneybackUserClient({
            graphqlSdk,
            ...getUserFromGql(user).data,
        });
    }
    throw new Error('initUodkaUserClient: No user account');
};
