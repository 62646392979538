import {
    RemittanceResult as GqlRemittanceResult,
    RemittanceResultCode,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';

export type RemittanceResult = {
    at: Date;
    code: RemittanceResultCode;
    fundsReturnedAt: Date | undefined;
};

export const getRemittanceResult = ({ at, code, fundsReturnedAt }: RemittanceResult): RemittanceResult => {
    return {
        at,
        code,
        fundsReturnedAt,
    };
};
export const getRemittanceResultFromGql = ({ at, code, fundsReturnedAt }: GqlRemittanceResult): RemittanceResult => {
    return {
        at: GqlDateTimeData.serialize(at),
        code,
        fundsReturnedAt: fundsReturnedAt ? GqlDateTimeData.serialize(fundsReturnedAt) : undefined,
    };
};
